<template>
  <div class="applyReview">
    <div :class="{'heed-cont': true, 'heed-shrink': !isShowTip}" v-if="isRealName()">
      <div class="heed-cont-head heed-cont-head-shrink" @click="showTip">
        <div class="heed-head-left heed-head-direction">
          <img src="@/assets/img/manualAudit/heed-warn.png" alt="">
          <span>证件照片注意事项</span>
        </div>
        <div class="heed-head-right heed-head-direction">
          <img v-show="!isShowTip" src="@/assets/img/manualAudit/item-bottom.png" alt="">
          <img v-show="isShowTip" src="@/assets/img/manualAudit/item-top.png" alt="">
        </div>
      </div>
      <transition name="fade">
        <div v-show="isShowTip">
          <div class="heed-cont-area">
            <div class="area-title">证件照片说明</div>
            <div class="area-text">
              <span>1.目前可支持证件类型为身份证、港澳居民来往内地通行证、台湾居民来往大陆通行证。</span>
              <span>2.证件照不清晰或与个人信息不匹配，将导致实名认证被驳回。</span>
              <span>3.您提供的证件信息将受到严格保护，仅用于身份验证，未经本人许可不会用于其他用途。</span>
            </div>
          </div>
          <div class="heed-cont-area">
            <div class="area-title">证件要求</div>
            <div class="area-text">
              <span>1.身份证、港澳居民来往内地通行证、台湾居民来往大陆通行证（卡式通行证）需上传本人露脸手持证件背面照＋正反面照片（不需手持），共3张。</span>
              <span>2.非卡式台湾居民来往大陆通行证需上传本人露脸手持通行证个人信息页（带有效盖章）照片＋通行证封面照片+通行证个人信息页 （不需手持）。</span>
              <span>3.证件必须在有效期内，有效期需在一个月以上。</span>
            </div>
          </div>
          <div class="heed-cont-area">
            <div class="area-title">照片要求</div>
            <div class="area-text">
              <span>1.本人手持证件正面露脸，五官清晰可辨。</span>
              <span>2.证件照上信息需完整且清晰可辨（无反光、遮挡、水印、证件套、logo等）。</span>
              <span>3.证件必须真实拍摄，不能使用复印件。</span>
              <span>4.确保照片完整（不缺边角）。</span>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="upload-area" v-if="isRealName('model')">
      <div class="upload-module">
        <div class="upload-module-head">
          <span><span style="color:red;">*</span>上传露脸手持证件照</span>
          <span @click="showPopup('face')">查看示例</span>
        </div>
        <div class="upload-module-tip">
          <img src="@/assets/img/manualAudit/safe-icon.png" alt="">
          <span>根据学习要求证件照片仅用于实名认证</span>
        </div>
        <div class="upload-module-opera">
          <div @click.stop="showEvent('face')">
            <template v-if="!formInline.faceUrl">
              <div class="imgBg"  v-if="$route.query.realName==='gat'"></div>
              <img v-else src="@/assets/img/manualAudit/1.png" alt="">
              <img class="photoImg" src="@/assets/img/manualAudit/photo-icon.png" alt="">
            </template>
            <div class="img-area" v-else>
              <!-- <img :src="formInline.faceUrl" alt=""> -->
              <van-image
                fit="contain"
                :src="formInline.faceUrl"
              />
            </div>
          </div>
          <div>照片不大于10M</div>
        </div>
      </div>
    </div>
    <div class="upload-area">
      <div class="upload-module">
        <div class="upload-module-head">
          <span><span style="color:red;">*</span>{{$route.query.realName==='dl'?'上传身份证人像面':'上传证件正面照'}}</span>
          <span v-if="!$route.query.realName" @click="showPopup('IDcardFront')">查看示例</span>
        </div>
        <div class="upload-module-tip">
          <img src="@/assets/img/manualAudit/safe-icon.png" alt="">
          <span>根据学习要求证件照片仅用于实名认证</span>
        </div>
        <div class="upload-module-opera">
          <div @click="showEvent('IDcardFront')">
            <template v-if="!formInline.cardIdFront">
              <div class="imgBg" v-if="$route.query.realName==='gat'"></div>
              <img v-else src="@/assets/img/manualAudit/3.png" alt="">
              <img class="photoImg" src="@/assets/img/manualAudit/photo-icon.png" alt="">
            </template>
            <div class="img-area" v-else>
              <!-- <img :src="formInline.cardIdFront" alt=""> -->
              <van-image
                fit="contain"
                :src="formInline.cardIdFront"
              />
            </div>
          </div>
          <div>照片不大于10M</div>
        </div>
      </div>
    </div>
    <div class="upload-area">
      <div class="upload-module">
        <div class="upload-module-head">
          <span><span style="color:red;">*</span>{{$route.query.realName==='dl'?'上传身份证国徽面':'上传证件背面照'}}</span>
          <span v-if="!$route.query.realName" @click="showPopup('IDcardBack')">查看示例</span>
        </div>
        <div class="upload-module-tip">
          <img src="@/assets/img/manualAudit/safe-icon.png" alt="">
          <span>根据学习要求证件照片仅用于实名认证</span>
        </div>
        <div class="upload-module-opera">
          <div @click.stop="showEvent('IDcardBack')">
            <template v-if="!formInline.cardIdReverse">
              <div class="imgBg" v-if="$route.query.realName==='gat'"></div>
              <img v-else src="@/assets/img/manualAudit/2.png" alt="">
              <img class="photoImg" src="@/assets/img/manualAudit/photo-icon.png" alt="">
            </template>
            <div class="img-area" v-else>
              <!-- <img :src="formInline.cardIdReverse" alt=""> -->
              <van-image
                fit="contain"
                :src="formInline.cardIdReverse"
              />
            </div>
          </div>
          <div>照片不大于10M</div>
        </div>
      </div>
    </div>
    <div class="applyReview-foot">
      <van-button round type="info" @click="enterEvent">确认提交审核</van-button>
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />

    <!-- 查看实例 -->
    <van-popup class="popup-cont" v-model="showPhotoTip">
      <span>查看实例</span>
      <!-- <img :src="popupImg" alt=""> -->
      <van-image
        fit="contain"
        :src="popupImg"
      />
      <span>{{popupText}}</span>
      <div @click.stop="showPopup">关闭</div>
    </van-popup>

    <!--打开相机-->
    <input style="display: none" type="file" ref="videoDom" accept="image/*" capture="camera" @change="videoChange">

    <!-- 调用相机和相册 -->
    <input style="display: none" type="file" ref="imgDom" accept="image/*" @change="imgChange">

    <!--打开摄像-->
    <!-- <input type="file" ref="videoDom" accept="video/*" capture="camcorder" @change="videoChange"> -->
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { upload } from '../../api/fileUpload'
export default {
  name: 'applyReview',
  data() {
    return {
      formInline: {
        cardIdFront: null, // 身份证正面
        cardIdReverse: null, // 身份证反面
        faceUrl: null // 手持证件照片
      },
      isShowTip: false,
      show: false,
      actions: [{ name: '拍照', value: 'video' }, { name: '相册上传', value: 'image' }],
      showPhotoTip: false,
      popupText: null,
      popupImg: null,
      moduleName: null
    }
  },
  methods: {
    isRealName(val) {
      if (this.$route.query.realName) {
        if (val==='model') {
          return false
        } else {
          // console.log('实名认证' + this.$route.query.realName==='gat' ? '港澳台' : '大陆');
          return this.$route.query.realName === 'gat' ? true : false
        }
      } else {
        return true
      }
    },
    showTip() {
      this.isShowTip = !this.isShowTip
    },
    showEvent(name) {
      this.moduleName = name
      this.show = true
    },
    onSelect(item) {
      console.log(item);
      if(item.value == 'video') {
        this.$refs.videoDom.value = ''
        this.$refs.videoDom.click()
      } else if(item.value == 'image') {
        this.$refs.imgDom.value = ''
        this.$refs.imgDom.click()
      }
    },
    async imgChange() {
      let inputDOM = this.$refs.imgDom;
      let files = inputDOM.files[0];
      let fileSize = files.size / 1024 / 1024
      if(fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      let obj = await upload(files);
      // console.log(obj,'相册985');
      if(this.moduleName == 'face')
        this.formInline.faceUrl = obj.excelUrl
      else if(this.moduleName == 'IDcardFront')
        this.formInline.cardIdFront = obj.excelUrl
      else
        this.formInline.cardIdReverse = obj.excelUrl
    },
    async videoChange() {
      let inputDOM = this.$refs.videoDom;
      let files = inputDOM.files[0];
      let fileSize = files.size / 1024 / 1024
      if(fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      let obj = await upload(files);
      // console.log(obj,'拍照985');
      if(this.moduleName == 'face')
        this.formInline.faceUrl = obj.excelUrl
      else if(this.moduleName == 'IDcardFront')
        this.formInline.cardIdFront = obj.excelUrl
      else
        this.formInline.cardIdReverse = obj.excelUrl
    },
    showPopup(name) {
      switch(name) {
        case 'face':
          this.popupImg = require('@/assets/img/manualAudit/1_1.png')
          this.popupText = '手持证件照'
          break;
        case 'IDcardFront':
          this.popupImg = require('@/assets/img/manualAudit/2_1.png')
          this.popupText = '证件正面照'
          break
        case 'IDcardBack':
          this.popupImg = require('@/assets/img/manualAudit/3_1.png')
          this.popupText = '证件背面照'
          break
      }
      this.showPhotoTip = !this.showPhotoTip
    },
    // 提交申请
    enterEvent() {
      if(!this.formInline.faceUrl && this.isRealName('model')) {
        this.Error('露脸手持证件照未上传')
        return
      }
      if(!this.formInline.cardIdFront) {
        this.Error('证件正面照未上传')
        return
      }
      if(!this.formInline.cardIdReverse) {
        this.Error('证件背面照未上传')
        return
      }
      Dialog.confirm({
        message: '确定资料无误要提交审核吗？',
        className: 'dialog-apply',
        confirmButtonColor: '#1A7EE2',
        cancelButtonColor: '#999999'
      })
      .then(() => {
        // on confirm
        this.applyEvent()
      })
      .catch(() => {
        // on cancel
      })
    },
    applyEvent() {
      if (!this.isRealName('model')) {
        let obj = {
          idCardFront: this.formInline.cardIdFront,
          idCardBack: this.formInline.cardIdReverse
        }
        console.log(obj,'objobj');
        this.toRealNameStuta(obj)
      } else {
        this.$request.auditReview(this.formInline).then(res => {
          if(res.data.code == 0) {
            this.goBack()
          }
        })
      }
    },
    toRealNameStuta(obj) {
      let type = this.$store.state.deviceType
      if(type == 1)
        window.webkit.messageHandlers.auditOver.postMessage(JSON.stringify(obj))
      else if(type == 2)
        window.Android.auditRealName(JSON.stringify(obj))
    },
    goBack() {
      let type = this.$store.state.deviceType
      if(type == 1)
        window.webkit.messageHandlers.gotoMine.postMessage(null)
      else if(type == 2)
        window.Android.gotoUser()
    },
  },
}
</script>

<style lang='scss'>
  .applyReview {
    height: 100vh;
    overflow: scroll;
    box-sizing: border-box;
    padding: 16px 16px 0;
    background-color: #F9F9F9;
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 300ms
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0
    }
    .heed-cont {
      background-color: #fff;
      padding: 0 16px 16px;
      border-radius: 4px;
      .heed-cont-head {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 9px 0 16px;
        border-bottom: 1px solid #EFEFEF;
        .heed-head-direction {
          display: flex;
          align-items: center;
        }
        .heed-head-left {
          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          span {
            font-size: 15px;
            font-weight: 400;
            color: #363840;
          }
        }
        .heed-head-right > img {
          width: 13px;
          height: 8px;
        }
      }
      .heed-cont-area {
        margin-top: 16px;
        .area-title {
          color: #363840;
          font-size: 16px;
        }
        .area-text {
          color: #666666;
          font-size: 14px;
          span {
            display: block;
            line-height: 26px;
          }
        }
      }
    }
    .heed-shrink {
      padding: 0 16px;
      .heed-cont-head-shrink {
        border: 0 none;
        padding: 9px 0;
      }
    }
    .upload-area {
      .upload-module {
        margin-top: 16px;
        padding: 15px 16px 17px;
        background-color: #fff;
        border-radius: 10px;
        .upload-module-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            display: inline-block;
          }
          &>span:first-of-type {
            color: #363840;
            font-size: 16px;
          }
          &>span:nth-of-type(2) {
            color: #1A7EE2;
            font-size: 14px;
          }
        }
        .upload-module-tip {
          margin: 16px 0;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 16px;
            margin-right: 3px;
            vertical-align: middle;
          }
          span {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
        }
        .upload-module-opera {
          text-align: center;
          img {
            width: 264px;
            height: 151px;
          }
          .imgBg {
            width: 264px;
            height: 151px;
            background: #f8f8f8;
            border-radius: 5px;
          }
          div:first-of-type {
            position: relative;
            display: inline-block;
            // & > img:nth-of-type(2) {
            .photoImg {
              width: 100px;
              height: 101px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .img-area {
              width: 264px;
              height: 151px;
              overflow: hidden;
              // & > img {
              //   width: 100%;
              //   height: auto;
              // }
            }
          }
          div:nth-of-type(2) {
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
    .upload-area:nth-of-type(4) {
      margin-bottom: 78px;
    }
    .applyReview-foot {
      width: 100%;
      height: 58px;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #fff;
      box-shadow: 0px -6px 12px 0px rgba(227,227,227,0.5);
      text-align: center;
      box-sizing: border-box;
      padding-top: 7px;
      padding-bottom: env(safe-area-inset-bottom);
      padding-bottom: constant(safe-area-inset-bottom);
    }
    .popup-cont {
      width: 293px;
      height: 297px;
      background: #FFFFFF;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span:first-of-type {
        margin-top: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #363840;
      }
      span:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
      div {
        color: #999999;
        font-size: 16px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-top: 1px solid #EFEFEF;
      }
      .van-image {
        width: 261px;
        height: 149px;
        border: 0 none;
      }
    }
  }
  .dialog-apply {
    .van-dialog__message {
      font-size: 16px;
      font-weight: 400;
      color: #363840;
    }
  }
</style>